.kep-login-facebook{
    width: 100%;
    border-radius: 35px !important;
    padding: 10px !important;
    opacity: 0.8;
    font-size: 14px !important;
    background-color: #3b5998;
    
}
.kep-login-facebook:hover{
    background-color : #4b649b !important;
}