.full-width{
    width:100% !important;
}
.width-200{
    width:200px !important;
}
.left-n-10{
    left : -10%;
}
.top-10{
    top: 10%;
}
.top-n-33{
    top:-33%;
}
.left-l-n-37{
    left:-37%;
}
.pull-right{
    float: right;
}